import React from 'react';
import { useTranslation } from 'react-i18next';

import { QAnswerBackButton } from '@widgets/questions-answers/specific/QAnswerBackButton';
import { QASpecificOtherQuestions } from '@widgets/questions-answers/specific/QASpecificOtherQuestions';
import { QASpecificProvider } from '@widgets/questions-answers/specific/QASpecificProvider';

interface QuestionLayoutProps {
  location: unknown;

  question: string;
}

export const QuestionLayout: React.FC<QuestionLayoutProps> = ({ children, location, question }) => {
  const { t } = useTranslation();

  return (
    <QASpecificProvider question={question}>
      <div className="pt-6 pb-12 lg:pt-12 xl:pb-52">
        <div className="container_xl">
          <QAnswerBackButton location={location} />
          <h1 className="mt-4 text-2xl lg:mt-6 lg:text-4xl">{t(question)}</h1>

          <div className="mt-8 lg:mt-16 lg:flex lg:gap-x-8 items-start justify-between">
            <div>{children}</div>

            <QASpecificOtherQuestions />
          </div>
        </div>
      </div>
    </QASpecificProvider>
  );
};

export default QuestionLayout;
