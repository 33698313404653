import React from 'react';
import { useTranslation } from 'react-i18next';

import { useLanguage } from '@hooks/useLanguage';

/**
 * NOTE: we store these translations as hard-coder for now.
 *
 * In future when we move translations on the client-side we replace this with usual i18n.
 */
const useTexts = () => {
  return React.useMemo(
    () => ({
      'legion-instruction-header': {
        uk: 'Нагорода легіонера єДопоможців',
        en: 'Legionnaires Award eDopomoga',
        fr: 'Prix des légionnaires eDopomoga',
      },

      'legion-instruction-answer-1': {
        uk: 'Нагорода легіонера єДопоможця - це ваша відзнака як благодійника на платформі єДопомога Волонтерська. В залежності від кількості закритих заявок на платформі, благодійник нагороджується відповідно нагородою.',
        en: 'The eDopomoga legionary award is your award as a benefactor on the eDopomoga platform. Depending on the number of closed applications on the platform, the benefactor is rewarded accordingly.',
        fr: "Le prix légionnaire eDopomoga est votre récompense en tant que bienfaiteur sur la plateforme eDopomoga. En fonction du nombre d'applications fermées sur la plateforme, le bienfaiteur est récompensé en conséquence.",
      },

      'legion-instruction-answer-2': {
        uk: (
          <>
            <p className="mb-2">Всього є п’ять рівнів нагород: діамантова, сапфірова, золота, срібна, бронзова.</p>

            <p>
              Рівень нагороди залежить від кількості закритих заявок на платформі єДопомога. Чим більше кількість
              закритих заявок, тим вище рівень нагороди благодійника.
            </p>
          </>
        ),
        en: (
          <>
            <p className="mb-2">There are five levels of awards in total: diamond, sapphire, gold, silver, bronze.</p>

            <p>
              The reward level depends on the number of closed applications on the eDopomoga platform. The higher the
              number of closed applications, the higher the reward level of the benefactor.
            </p>
          </>
        ),
        fr: (
          <>
            <p className="mb-2">Il existe cinq niveaux de récompenses au total: diamant, saphir, or, argent, bronze.</p>

            <p>
              Le niveau de récompense dépend du nombre d\'applications fermées sur la plateforme eDopomoga. Plus le
              nombre d\'applications fermées est élevé, plus le niveau de récompense du bienfaiteur est élevé.
            </p>
          </>
        ),
      },

      'legion-instruction-answer-3': {
        uk: 'Нагорода відображається у власному кабінеті Благодійника.',
        en: "The award is displayed in the Benefactor's own account.",
        fr: 'Le prix est affiché dans le propre compte du bienfaiteur.',
      },

      'legion-instruction-answer-4': {
        uk: (
          <>
            <p className="mb-2">
              Нагородами, які будуть відображатися в особистому кабінеті, можна буде ділитися на сторінках своїх
              соц.мереж. Таким чином, ви показуєте своїм прикладом, що допомагати це круто.
            </p>

            <p className="mb-2">
              Чим більше разів благодійник поширить свою нагороду, тим більше ймовірність отримати, ще додаткову
              відзнаку від платформи єДопомога.
            </p>

            <p>
              Обов’язкова умова тегнути сторінку єДопомога в соц.мережах перед публікацією нагороди на своїй сторінці.
            </p>
          </>
        ),
        en: 'Awards that will be displayed in your personal account can be shared on your social media pages. In this way, you show by your example that helping is cool.',
        fr: "Les récompenses qui seront affichées dans votre compte personnel peuvent être partagées sur vos pages de médias sociaux. De cette façon, vous montrez par votre exemple qu'aider, c'est cool.",
      },

      'legion-instruction-bronze': {
        uk: 'бронзова нагорода легіонера від 5 до 29 закритих заявок',
        en: 'bronze legionnaire award from 5 to 29 closed applications',
        fr: 'Prix du légionnaire de bronze de 5 à 29 applications fermées',
      },

      'legion-instruction-silver': {
        uk: 'срібна нагорода легіонера від 30 до 99 закритих заявок',
        en: 'silver legionnaire award from 30 to 99 closed applications',
        fr: "Prix du légionnaire d'argent de 30 à 99 applications fermées",
      },

      'legion-instruction-gold': {
        uk: 'золота нагорода легіонера від 100 до 249 закритих заявок',
        en: 'gold legionary award from 100 to 249 closed applications',
        fr: "Prix du légionnaire d'or de 100 à 249 applications fermées",
      },

      'legion-instruction-sapphire': {
        uk: 'сапфірова нагорода легіонера від 250 до 449 закритих заявок',
        en: 'sapphire legionnaire award 250 to 449 closed applications',
        fr: 'Prix du légionnaire saphir de 250 à 449 applications fermées',
      },

      'legion-instruction-diamant': {
        uk: 'діамантова нагорода легіонера від 450 закритих заявок',
        en: 'legionnaire diamond award from 450 closed applications',
        fr: 'Prix ​​du légionnaire diamant de 450 applications fermées',
      },
    }),
    [],
  );
};

export const useGetLegionInstructionTextByLanguage = () => {
  const { t: i18n } = useTranslation();
  const language = useLanguage();

  const texts = useTexts();

  return React.useMemo(
    () => (key: string) => {
      // @ts-ignore
      return texts[key]?.[language] || texts[key]?.en || i18n(key);
    },
    [language],
  );
};
